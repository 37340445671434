<template>
    <div class="calendrierpage">
        <NavBar />
        <BannerCompo />
        <div class="calendriercontainer">
            <h1 class="titrepagepart">Notre planning de l'édition 2024/2025: </h1>
            <h2 class="titresectionpart">Date limite d'envoi : </h2>
            <p>Pour la session 2024/2025 du prix, les participants pourront envoyer leurs écrits jusqu'au 15 Octobre 2025.</p>
            <h2 class="titresectionpart">Remise des prix : </h2>
            <p>La remise des prix pour 2024/2025 aura lieu courant janvier 2026</p>
        </div>
        <FooterCompo />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import BannerCompo from '@/components/BannerCompo.vue';
import FooterCompo from '@/components/FooterCompo.vue';
export default {
    name: 'CalendrierPage',
    components: {
        NavBar,
        BannerCompo,
        FooterCompo
    }
};
</script>

<style scoped>
.calendrierpage {
    background-color: #fff;
}

.calendriercontainer{
    margin: 2em;
}

.titrepagepart {
    margin-top: .5em;
    font-family: Amatic SC, cursive;
    font-size: 3em;
    font-weight: bold;
    margin-bottom: .5em;
}

.titresectionpart {
    font-family: Dancing Script, cursive;
    font-size: 2.5em;
    color: #319093;
    margin-top: 1em;
}
</style>