<template>
    <div class="actupage">
        <NavBar />
        <BannerCompo />
        <div class="containeractu">
            <h1 class="titrepage">Nos actualités : </h1>
            <h2 id="titresection">Retrouvez ici nos derniers évènements : </h2>
            <div id="sectionacc">
                <h3 id="titresection2">Notre lauréate : madame Anne-Sophie PROST de 78000 VERSAILLES -
                    YVELINES</h3>
                <h4 class="soustitresect">le 10 Janvier 2025</h4>
                <p>
                    Après des études littéraires, j’ai travaillé dans la communication d’entreprise et exercé pendant
                    une douzaine d’années le métier de rédactrice indépendante. En parallèle de mes prestations pour
                    différents clients, j’ai développé mon goût pour l’écriture créative en rédigeant des nouvelles.
                    Plusieurs ont été primées suite à des concours, ce qui m’a encouragée à publier en 2023, en
                    auto-édition, un recueil de nouvelles intitulé Sur le fil.

                    J'aime alterner les genres littéraires, travailler la psychologie de mes personnages, soigner la
                    construction de mes récits et notamment les chutes, que je veux surprenantes !

                    En 2017, je me suis formée pour animer des ateliers d'écriture, afin d’être en mesure de communiquer
                    les joies et les techniques de l’écriture à d’autres. J’anime ainsi aujourd’hui 5 ateliers
                    d’écriture réguliers pour adultes à Versailles, ainsi que des stages d’écriture créative pour les
                    collégiens pendant les vacances.

                    J'ai ainsi la chance d'avoir fait de ma passion pour l'écriture mon métier. J'aime en explorer
                    toutes les formes pour aiguiser ma plume d'auteur et celle de tous ceux que j'ai la joie
                    d'accompagner en atelier.

                    En tant que mère de 4 enfants, j’ai eu envie au début de cette nouvelle de m’amuser avec les
                    projections que l’on fait sur nos enfants, avec les métiers qu’on leur imagine en fonction de leurs
                    goûts et de leur évolution au fil des années.

                    J’ai également joué avec les clichés associés à la vie et aux lieux parisiens emblématiques, et
                    glissé un petit clin d’œil aux « amoureux des bancs publics » de Brassens.

                    J’ai voulu donner une intensité plus forte et une profondeur à mon histoire avec ce personnage
                    féminin confronté à l’inexorabilité de la mort et à la question de ce que nous laissons après nous,
                    un sujet qui m’habite et qui parcourt d’autres de mes nouvelles. Et j’ai souhaité laisser la part
                    belle à l’émotion.
                </p>
            </div>
            <div id="sectionacc">
                <h3 id="titresection2">Notre mention de prix : madame Josselyne SERE de 24240 GAGEAC ET ROUILLAC - DORDOGNE</h3>
                <h4 class="soustitresect">le 10 Janvier 2025</h4>
                <p>Ne pouvant se déplacer, elle a chargé la présidente madame Jocelyne BOUR de la présenter par ses mots
                    : <br>

                    Ce soir, vendredi 10 janvier, je ne suis pas dans votre belle région mais en Dordogne où je réside.
                    Néanmoins par la pensée je suis avec vous et pour me présenter, Jocelyne, votre présidente, vous lit
                    ces quelques lignes :<br>

                    Je me prénomme Josselyne (avec deux S et un Y) et, si mes longs cheveux ne sont pas blancs, c’est
                    grâce au talent de ma coiffeuse.<br>

                    Peintre aquarelliste, j’ai un jour posé le pinceau pour prendre la plume.<br>

                    Tout a commencé par ces mots : « Nana aconte-moi une histoi »<br>

                    Blotti dans mes bras, mon petit-fils Maxime a écouté mon premier conte : « L’histoire de Rémi, » ce
                    petit garçon aux yeux bleus malicieux.<br>

                    Ce soir-là, la nouvelliste était née.<br>

                    En effet, Maxime me demandait toujours de nouvelles histoires, me donnant même le sujet. Puis,
                    invitée dans son école, je devins conteuse.<br>

                    Ce sont les professeurs qui m’ont encouragée à écrire pour les adultes.<br>

                    J’ai alors écrit une première nouvelle : « Un petit mot pour vous dire ».<br>

                    Envoyée au Grand Concours du Monde Francophone de l’Académie Poétique et Littéraire de Provence,
                    elle reçut le prix d’honneur. C’était en 2006.<br>

                    Depuis, je n’ai plus jamais cessé d’écrire.<br>

                    Plus récemment, le 9 décembre dernier, un prix m’a été décerné par l’Association Littéraire La Lampe
                    de Chevet pour ma nouvelle « Julia ».</p>
            </div>
            <div id="sectionacc">
                <h3 id="titresection2">Remise du prix 2022/2023</h3>
                <h4 class="soustitresect">le 10 Janvier 2025</h4>
                <div class="galerie">
                    <div class="columns">
                        <div class="column is-one-third">
                            <figure class="imactu"><img src="/media/Actu1.jpeg" alt="Actualités"></figure>
                        </div>
                        <div class="column is-one-third">
                            <figure class="imactu"><img src="/media/Actu2.jpeg" alt="Actualités"></figure>
                        </div>
                        <div class="column is-one-third">
                            <figure class="imactu"><img src="/media/Actu3.jpeg" alt="Actualités"></figure>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-half">
                            <figure class="imactu"><img src="/media/Actu4.jpeg" alt="Actualités"></figure>
                        </div>
                        <div class="column is-half">
                            <figure class="imactu"><img src="/media/Actu5.jpeg" alt="Actualités"></figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterCompo />
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterCompo from '@/components/FooterCompo.vue';
import BannerCompo from '@/components/BannerCompo.vue';
export default {
    name: 'ActuPage',
    components: {
        NavBar,
        FooterCompo,
        BannerCompo
    },

};
</script>

<style scoped>
.actupage {
    background-color: #fff;
}

.containeractu {
    padding: 2em;
}

.titrepage {
    margin-top: .5em;
    font-family: Amatic SC, cursive;
    font-size: 3em;
    font-weight: bold;
    margin-bottom: .5em;

}

#titresection {
    font-family: Dancing Script, cursive;
    font-size: 2.5em;
    color: #319093;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

#sectionacc {
    display: inline-block;
    border: 3px solid #333;
    border-radius: 2% 6% 5% 4%/1% 1% 2% 4%;
    background: #fff;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    margin-bottom: 1em;
    width: 90vw;
    margin-left: .5em;
    padding-bottom: 1em;
}

#titresection2,
#titresection[data-v-244e49a0] {
    font-family: Dancing Script, cursive;
    font-size: 2em;
    color: #319093;
    width: 80vw;
}

.soustitresect {
    font-family: Amatic SC, cursive;
    font-size: 1.75em;
    color: #d3d3d3;
    font-weight: bold;
}

.galerie {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
}

.imactu {
    width: 20em;
}
</style>